const toastTimeout = callback => setTimeout(callback, 3000);


const state = {
    queue: [],
    active: undefined,
    timeoutId: undefined,
};

const getters = {
    activeNotification(state) {
        return state.active;
    },

}

const actions = {
    pushToast({ state, commit, dispatch }, toast) {
        // add the new toast to the queue
        commit('enqueue', { toast });

        // if there isn't already active toast, dispatch the
        // processQueue action immediately show it and will
        // continue handling any toasts that get added after
        if (!state.active) dispatch('processQueue');
    },
    processQueue({ state, commit, dispatch },) {
        // In case this action gets dispatched with nothing to do
        if (!state.queue.length && !state.active) return;

        // If the queue is empty, but there's an active toast,
        // we just need to reset the state after the display timeout
        if (!state.queue.length && state.active)
            return toastTimeout(() => commit('reset'));

        // This just uses setTimeout to recursively dispatch this
        // action to display each toast 1 at a time until the queue
        // is empty
        const timeoutId = toastTimeout(() => dispatch('processQueue'));

        // Dequeue the next toast and add the timeoutId to the
        // store so it can be cancelled if necessary
        commit('dequeue', { timeoutId });
    },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    enqueue(state, { toast }) {
        state.queue = [...state.queue, toast];
    },
    dequeue(state, { timeoutId }) {
        const [active, ...queue] = state.queue;

        state.active = active;
        state.queue = queue;
    },
    reset({ timeoutId } = state) {
        if (timeoutId) clearTimeout(timeoutId);

        state.active = undefined;
        state.timeout = undefined;

    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
