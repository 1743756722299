<template>
  <button class="btn btn-sm btn-secondary" @click.prevent="createChatRoom" v-if="userid != currentUser.user_id">
    <i class="pi pi-comment"  style="font-size: 0.9rem"></i>
    <template v-if="text">{{ $t("user.profile.message") }}</template>
  </button>
</template>


<script>
import { mapGetters } from "vuex";
import ChatService from "../service/ChatService";

export default {
  name: "RwvParticipantsPreview",
  components: {},
  props: {
    userid: { type: Number, required: true },
    text: { type: Boolean, required: false, default: false },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  created() {
    this.service = new ChatService();
  },
  methods: {
    createChatRoom() {
      this.service.createOneToOne(this.userid).then((data) => {
        this.$router.push("/chat/" + data.room_id);
      });

      return false;
    },
  },
};
</script>