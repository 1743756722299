
import Vue from "vue";
import ApiService from "@/common/api.service";
import {
  FETCH_PAGE, FETCH_PAGE_PUBLIC, FETCH_REDIRECT, FETCH_REDIRECT_PUBLIC
} from "./actions.type";
import {
  SET_PAGE, SET_REDIRECT, SET_REDIRECT_PUBLIC
} from "./mutations.type";

const initialState = {
  page: {},
  isPageLoading: true,
  pageErrors: false,
  videocallUrl: '',
  roomUrl: '',
  setRedirect: false,
  setRedirectPublic: false,
};


export const state = { ...initialState };

export const actions = {
  async [FETCH_PAGE](context, params) {
    context.commit('setPagesLoadingStart');
    return ApiService.query('pages/get/' + params.page_name,
      {
        params: params
      }
    )
      .then(({ data }) => {
        context.commit(SET_PAGE, data);
      })
      .catch(error => {
        context.commit('setPageErrors', true);
        context.commit('setPagesLoadingEnd');
        throw new Error(error);
      });
  },
  async [FETCH_PAGE_PUBLIC](context, params) {
    context.commit('setPagesLoadingStart');
    return ApiService.query('pagesPublic/get/' + params.page_name,
      {
        params: params
      }
    )
      .then(({ data }) => {
        context.commit(SET_PAGE, data);
      })
      .catch(error => {
        context.commit('setPageErrors', true);
        context.commit('setPagesLoadingEnd');
        throw new Error(error);
      });
  },
  async [FETCH_REDIRECT](context, params) {
    context.commit('setPagesLoadingStart');
    return ApiService.query('pages/getRedirect/',
      {
        params: params
      }
    )
      .then(({ data }) => {
        context.commit(SET_REDIRECT, data);
      })
      .catch(error => {
        context.commit('setPagesLoadingEnd');
        throw new Error(error);

      });
  },
  async [FETCH_REDIRECT_PUBLIC](context, params) {
    context.commit('setPagesLoadingStart');
    console.log(params);
    return ApiService.query('pagesPublic/getRedirect/',
      {
        params: params
      }
    )
      .then(({ data }) => {
        context.commit('setPagesLoadingEnd');
        context.commit(SET_REDIRECT, data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  pageErrorsHandled(context) {
    context.commit('setPageErrors', false);
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  [SET_PAGE](state, { data, roomUrl, videocallUrl }) {
    state.page = data;
    state.roomUrl = roomUrl
    state.videocallUrl = videocallUrl;
    state.isPageLoading = false;
  },
  [SET_REDIRECT](state, { redirect }) {
    state.setRedirect = redirect;
    state.isPageLoading = false;

  },
  [SET_REDIRECT_PUBLIC](state, { redirect }) {
    state.setRedirectPublic = redirect;
    state.isPageLoading = false;
  },
  /*[RESET_STATE]() {
    for (let f in state) {
      Vue.set(state, f, initialState[f]);
    }
  },*/
  setPagesLoadingStart() {
    state.isPageLoading = true;
    state.pageErrors = false;
  },
  setPagesLoadingEnd() {
    state.isPageLoading = false;
  },
  setPageErrors(state, value ) {
    state.pageErrors = value;
  },
};

const getters = {
  page(state) {
    return state.page;
  },
  proomUrl(state) {
    return state.roomUrl;
  },
  pvideocallUrl(state) {
    return state.videocallUrl;
  },
  isPageLoading(state) {
    return state.isPageLoading;
  },
  pageErrors(state) {
    return state.pageErrors;
  },
  setRedirect(state) {
    return state.setRedirect;
  },
  setRedirectPublic(state) {
    return state.setRedirectPublic;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
