<template>
  <div class="wrapper">
    <div class="auth-content">
      <Card>
        <template #content>
          <LoginHeader class="text-center" />

          <div v-if="text" v-html="text">
          </div>

          <Message severity='success' :closable="true" v-if="successMessage">
            {{ $t(successMessage) }}
          </Message>

          <Message severity='error' :closable="true" v-if="errorMessage">
            {{ errorMessage }}
          </Message>

          <h6 class="">{{ $t("signin") }}</h6>

          <form @submit.prevent="onSubmit(email, login_name, password)">
            <div class="container-flex">
              <div class="row">
                <div class="field mb-2 col-12" v-if="!eventConfig.anonymousMode || adminLogin">
                  <InputText class="w-100" id="login-email" type="email" v-model="email"
                    :placeholder="$t('login.email')" />
                </div>
                <div class="field mb-2 col-12" v-if="eventConfig.anonymousMode">
                  <InputText class="w-100" id="login-name" type="username" v-model="login_name"
                    :placeholder="$t('signup.login_name')" />
                </div>

                <div class="field mb-2 col-12">
                  <InputText class="w-100" id="login-name" type="password" v-model="password"
                    :placeholder="$t('login.password')" />
                </div>

                <div class="col-12">
                  <transition name="slide-fade">
                    <Message severity='info' :closable="false" v-if="errors != null && errors.length > 0">
                      <div v-for="(error, index) in errors" :key="index">
                        {{ error }}
                      </div>
                    </Message>
                  </transition>
                </div>

                <div class="col-12">
                  <Button type="submit" class="mb-4 w-100" :disabled="loading" :icon="spinnerComputed"
                    :label="$t('login')" />
                </div>
              </div>
            </div>
          </form>

          <div class="text-start mb-2 small">
            <p>
              <router-link to="/forgotPassword">{{ $t("forgotpassword") }}</router-link>
            </p>
          </div>

          <div v-if="!isAuthenticated && eventConfig.signupEnabled == 1" class="text-start mb-2 small">
            <p>
              {{ $t("backend.notSignedup") }}
              <router-link to="/signup" style="text-transform: uppercase; font-weight: bold">{{ $t("backend.signup")
                }}</router-link>
            </p>
          </div>
          <LoginFooter />
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { LOGIN, GET_CONFIG } from "@/store/actions.type";
import { UNSET_ERROR } from "@/store/mutations.type";
import LoginFooter from "@/components/TheLoginFooter";
import LoginHeader from "@/components/TheLoginHeader";

export default {
  name: "RwvLogin",
  components: {
    LoginFooter, LoginHeader
  },
  data() {
    return {
      email: null,
      login_name: null,
      password: null,
      adminLogin: false,
      loading: false,
      successMessage: null,
      errorMessage: null,
    };
  },
  methods: {
    onSubmit(email, login_name, password) {
      this.loading = true;
      this.$store
        .dispatch(LOGIN, {
          credentials: { email, login_name, password },
          lang: this.$i18n.locale,
        })
        .then(() => this.$router.push({ name: "Home" }))
    },
    resetErrors() {
      this.loading = false;
      if (this.errors == null) return;
      if (!(Object.entries(this.errors).length === 0)) {
        this.$store.commit(UNSET_ERROR);
      }
    },
    showMailField() {
      this.adminLogin = true;
      return false;
    },
  },
  watch: {
    email: function (val) {
      this.resetErrors();
    },
    password: function (val) {
      this.resetErrors();
    },
    errors: function (val) {
      this.loading = false;
    },
  },

  mounted() {
    if (this.isAuthenticated) {
      this.$router.push("/");
    }
    this.$store.dispatch(GET_CONFIG);
    if (this.$route.query.msg == "password-reset-success") {
      this.$notification.success(this.$t("user.password.reset.success"), {
        timer: 10,
      });
      this.successMessage = "user.password.reset.success.short";
      this.$router.replace({ name: "Login" })
    }
    if (this.$route.query.msg == "password-reset-error") {
      this.errorMessage = "user.password.reset.error";
      this.$notification.error(this.$t("user.password.reset.error"), {
        timer: 10,
      });
      this.$router.replace({ name: "Login" })
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["isAuthenticated", "eventConfig", "darkMode"]),
    spinnerComputed() {
      let icon = ''

      if (this.loading) icon = 'pi pi-spin pi-spinner'
      else icon = null;

      return icon;
    },
    text() {
      if (this.eventConfig == null)
        return null;
      if (this.eventConfig.lang1 == this.$i18n.locale)
        return this.eventConfig.textOnLoginPageLang1
      else if (this.eventConfig.lang2 == this.$i18n.locale)
        return this.eventConfig.textOnLoginPageLang2
      else if (this.eventConfig.lang3 == this.$i18n.locale)
        return this.eventConfig.textOnLoginPageLang3
      return null;
    }
  },
};
</script>


<style scoped>
.auth-content {
  position: relative;
  width: 480px;
  padding: 15px;
  z-index: 5;
}

.wrapper {
  margin: auto;
  display: flex;
  justify-content: center;
}
</style>
<style>
.cust-p-ignore p {
  margin-bottom: 0 !important;
}
</style>