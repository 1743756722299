<template>
  <div class="col-md-6 col-lg-4 mb-4">
    <b-card
    class="h-100"
      no-body
      :img-src="room.image && room.image.length > 0 ? room.image[0].url : ''"
      img-alt=""
      img-top
      :header-bg-variant="live ? 'success' : future ? 'primary' : ''"
      :border-variant="live ? 'success' : ''"
    >
      <div class="card-body">
        <RwvRoomsMeta isPreview :room="room" />
        <h5
          class="card-title"
          v-if="eventConfig.lang1 == lang"
          v-text="room.meeting_name_lang1"
        />
        <h5
          class="card-title"
          v-if="eventConfig.lang2 == lang"
          v-text="room.meeting_name_lang2"
        />
        <h5
          class="card-title"
          v-if="eventConfig.lang3 == lang"
          v-text="room.meeting_name_lang3"
        />
        <div
          v-if="eventConfig.lang1 == lang"
          class="card-text userGeneratedFormat"
          style="white-space: pre-line"
          v-html="room.meeting_description_lang1"
        />
        <div
          v-if="eventConfig.lang2 == lang"
          class="card-text userGeneratedFormat"
          style="white-space: pre-line"
          v-html="room.meeting_description_lang2"
        />
        <div
          v-if="eventConfig.lang3 == lang"
          class="card-text userGeneratedFormat"
          style="white-space: pre-line"
          v-html="room.meeting_description_lang3"
        />
        <p style="color: transparent">-</p>
        <a
          target="_blank"
          class="btn btn-primary"
          style="position: absolute; bottom: 1.25rem"
          :href="roomUrl + '/' + room.bbbroom_id"
          >{{ $t("join") }}</a
        >
      </div>
    </b-card>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import RwvRoomsMeta from "./RoomsMeta";

export default {
  name: "RwvRoomsPreview",
  components: {
    RwvRoomsMeta,
  },
  props: {
    room: { type: Object, required: true },
    roomUrl: { type: String, required: true },
  },
  methods: {
    goto: function (meeting_id) {
      alert(meeting_id);
      return false;
    },
  },
  data: function () {
    return {
      lang: false,
    };
  },
  mounted() {
    this.lang = this.$i18n.locale;

    if (
      this.room.meeting_name_lang2 === "" ||
      this.room.meeting_name_lang2 == null
    )
      this.room.meeting_name_lang2 = this.room.meeting_name_lang1;
    if (
      this.room.meeting_name_lang3 === "" ||
      this.room.meeting_name_lang3 == null
    )
      this.room.meeting_name_lang3 = this.room.meeting_name_lang1;

    if (
      this.room.meeting_description_lang2 === "" ||
      this.room.meeting_description_lang2 === "<p></p>" ||
      this.room.meeting_description_lang2 == null
    )
      this.room.meeting_description_lang2 = this.room.meeting_description_lang1;
    if (
      this.room.meeting_description_lang3 === "" ||
      this.room.meeting_description_lang3 === "<p></p>" ||
      this.room.meeting_description_lang3 == null
    )
      this.room.meeting_description_lang3 = this.room.meeting_description_lang1;
  },
  computed: {
    ...mapGetters(["eventConfig"]),
    roomLink() {
      return {
        name: "room",
        params: {
          slug: this.room.slug,
        },
      };
    },
  },
};
</script>



<style scoped lang="scss">
.userGeneratedFormat ::v-deep img {
  max-width: 100%;
  margin-bottom: 1em;
  max-height: 70vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.userGeneratedFormat ::v-deep {
  table {
    th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
      background-color: #e9ecef;
    }
    p {
      margin: 0;
    }
  }
}
</style>