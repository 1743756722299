<template>
  <div
    v-if="floatyDisplay"
    :style="{
      top: y + 'px',
      left: x + 'px',
      width: width + 'px',
      height: height + 'px',
    }"
    class="fixed-pos border bg-body"
    :class="{ videoFloater: floaty }"
  >
    <div v-if="floaty" class="headerFloater">
      <span class="videoTitle">{{ $t("backend.videoplayer") }}</span>
      <div class="floatRight">
        <b-btn
          size="sm"
          variant="light"
          :title="$t('returnToStream')"
          v-on:click="returnToStream"
        >
          <i class="ri-share-box-line"></i>
        </b-btn>
        <b-btn size="sm" variant="light" :title="$t('close')" v-on:click="removeFloaty">
          <i class="ri-close-line"></i>
        </b-btn>
      </div>
      <hr />
    </div>
    <div :style="{ height: floaty ? 'calc(100% - 30px)' : 'calc(100%)' }">
      <Video :embed-url="embedUrl" />
    </div>
  </div>
</template>

<script>
import Video from "./FloatingVideo";
import { mapGetters } from "vuex";
import { HIDE_FLOATY, SET_VIDEO_BOX } from "../store/mutations.type";

export default {
  name: "FloatingVideoPlayer",
  components: {
    Video,
  },
  computed: {
    ...mapGetters([
      "embedUrl",
      "x",
      "y",
      "width",
      "height",
      "floaty",
      "floatyDisplay",
      "eventConfig",
      "videoFrom",
    ]),
  },

  watch: {
    floaty(newValue) {
      this.moveFloaty();
    },
  },

  mount() {},

  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
    };
  },

  methods: {
    removeFloaty(event) {
      event;
      this.$store.commit(HIDE_FLOATY);
    },
    returnToStream()
    {
      this.$router.push(this.videoFrom);
    },
    moveFloaty() {
      var x = window.innerWidth - 410;
      var y = window.innerHeight - 275;
      if (x < 0) {
        x = 0;
      }
      if (y < 0) {
        y = 0;
      }
      if (this.floaty) {
        this.$store.commit(SET_VIDEO_BOX, [x, y, 100, 235]);
      }
    },
  },
  created() {
    window.addEventListener("resize", this.moveFloaty);
  },
  destroyed() {
    window.removeEventListener("resize", this.moveFloaty);
  },
};
</script>

<style scoped>
.videoFloater {
  position: fixed !important;
  top: 0px;
  left: 0px;
  width: 400px !important;
  height: 275px !important;
  z-index: 100;
  max-width: 100%;
}
.fixed-pos {
  position: absolute;
}

.headerFloater {
  height: 30px;
  padding: 0px;
}

.headerFloater .floatRight {
  float: right;
}
.videoTitle {
  padding: 0.5em;
  line-height: 2em;
}
</style>