<template>
  <div>
    <div class="mb-4">
      <img class="brand" style="max-height: 90px; max-width: 100%" :alt="eventConfig.titleLang1" v-if="!darkMode" :src="
        eventConfig.logo && eventConfig.logo.length > 0
          ? eventConfig.logo[0].url
          : '/dist/img/mute-logo-light.png'
      " />
      <img class="brand" style="max-height: 90px; max-width: 100%" :alt="eventConfig.titleLang1" v-if="darkMode" :src="
        eventConfig.logoDark && eventConfig.logoDark.length > 0
          ? eventConfig.logoDark[0].url
          : (eventConfig.logo && eventConfig.logo.length > 0
            ? eventConfig.logo[0].url
            : '/dist/img/mute-logo-dark.png')
      " />
    </div>

    <h3 class="mb-4" v-if="eventConfig.lang1 == lang">
      {{ eventConfig.titleLang1 }}
    </h3>
    <h3 class="mb-4" v-if="eventConfig.lang2 == lang">
      {{ eventConfig.titleLang2 }}
    </h3>
    <h3 class="mb-4" v-if="eventConfig.lang3 == lang">
      {{ eventConfig.titleLang3 }}
    </h3>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheLoginHeader",
  computed: {
    ...mapGetters(["darkMode", "eventConfig"]),
    lang: function () {
      return this.$i18n.locale;
    },
  },
  watch: {
    eventConfig(newValue) {
      if (
        this.eventConfig.titleLang2 === "" ||
        this.eventConfig.titleLang2 == null
      ) {
        this.eventConfig.titleLang2 = this.eventConfig.titleLang1;
      }
      if (
        this.eventConfig.titleLang3 === "" ||
        this.eventConfig.titleLang3 == null
      ) {
        this.eventConfig.titleLang3 = this.eventConfig.titleLang1;
      }
    },

  },
};

</script>