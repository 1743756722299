<template>
  <div>
    <div>
      <Dialog :header="$t('editor.imageInsert')" :visible.sync="imageModal" :containerStyle="{ width: '50vw' }"
        :modal="true">

        <FormulateForm @submit="submitHandler" name="imageInsert">
          <FormulateInput type="image" name="image" :label="$t('global.image')" :help="$t('global.image.help')"
            validation="mime:image/jpeg,image/png,image/gif" />
        </FormulateForm>

        <template #footer>
          <!--<Button :label="$t('editor.cancel')" icon="pi pi-times" @click="imageModalCancel" class="p-button-text" />-->
          <Button :label="$t('editor.insert')" icon="pi pi-check" @click="imageModalOk" autofocus />
        </template>
      </Dialog>
    </div>
    <div style="
        position: sticky;
        top: 59px;
        z-index: 1;
        background: var(--bs-body-bg);
        border-bottom: var(--bs-border-width) solid var(--bs-border-color)
      " v-if="editor" :data-type="context.type">
      <div>
        <span class="button" :title="$t('bold')" @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }">
          <i class="ri-bold"></i>
        </span>
        <span class="button" :title="$t('italic')" @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }">
          <i class="ri-italic"></i>
        </span>
        <span class="button" :title="$t('strike')" @click="editor.chain().focus().toggleStrike().run()"
          :class="{ 'is-active': editor.isActive('strike') }">
          <i class="ri-strikethrough"></i>
        </span>
        <span class="button" :title="$t('code')" @click="editor.chain().focus().toggleCode().run()"
          :class="{ 'is-active': editor.isActive('code') }">
          <i class="ri-code-s-slash-line"></i>
        </span>
        <span class="button" :title="$t('clearFormat')" @click="editor.chain().focus().unsetAllMarks().run()">
          <i class="ri-format-clear"></i>
        </span>
        <!--    <span class="button" @click="editor.chain().focus().clearNodes().run()">
        <i class="ri-format-clear"></i>
      </span>-->
        <span class="button" :title="$t('paragraph')" @click="editor.chain().focus().setParagraph().run()"
          :class="{ 'is-active': editor.isActive('paragraph') }">
          <i class="ri-paragraph"></i>
        </span>
        <span class="button" :title="$t('heading1')" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
          <i class="ri-h-1"></i>
        </span>
        <span class="button" :title="$t('heading2')" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
          <i class="ri-h-2"></i>
        </span>
        <span class="button" :title="$t('heading3')" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
          <i class="ri-h-3"></i>
        </span>
        <span class="button" :title="$t('heading4')" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
          <i class="ri-h-4"></i>
        </span>
        <span class="button" :title="$t('heading5')" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
          <i class="ri-h-5"></i>
        </span>
        <span class="button" :title="$t('heading6')" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
          <i class="ri-h-6"></i>
        </span>
        <span class="button" :title="$t('alignLeft')" @click="editor.chain().focus().setTextAlign('left').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
          <i class="ri-align-left"></i>
        </span>
        <span class="button" :title="$t('alignCenter')" @click="editor.chain().focus().setTextAlign('center').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
          <i class="ri-align-center"></i>
        </span>
        <span class="button" :title="$t('alignRight')" @click="editor.chain().focus().setTextAlign('right').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
          <i class="ri-align-right"></i>
        </span>
        <span class="button" :title="$t('alignJustify')" @click="editor.chain().focus().setTextAlign('justify').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
          <i class="ri-align-justify"></i>
        </span>
        <span class="button" :title="$t('bulletList')" @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }">
          <i class="ri-list-unordered"></i>
        </span>
        <span class="button" :title="$t('orderedList')" @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }">
          <i class="ri-list-ordered"></i>
        </span>
        <span class="button" :title="$t('codeBlock')" @click="editor.chain().focus().toggleCodeBlock().run()"
          :class="{ 'is-active': editor.isActive('codeBlock') }">
          <i class="ri-code-box-line"></i>
        </span>
        <span class="button" :title="$t('blockquote')" @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'is-active': editor.isActive('blockquote') }">
          <i class="ri-double-quotes-l"></i>
        </span>
        <span class="button" @click="setButton" :title="$t('insertButton')"
          :class="{ 'is-active': editor.isActive('button') }"><i class="ri-t-box-line"></i>
        </span>
        <span class="button" @click="setLink" :title="$t('insertLink')"
          :class="{ 'is-active': editor.isActive('link') }">
          <i class="ri-link"></i>
        </span>
        <span class="button" :title="$t('removeLink')" @click="editor.chain().focus().unsetLink().run()"
          v-if="editor.isActive('link')">
          <i class="ri-link-unlink"></i>
        </span>
        <span class="button" :title="$t('insertImage')" @click="addImage">
          <i class="ri-image-line"></i>
        </span>
        <span class="button" :title="$t('horizontalRule')" @click="editor.chain().focus().setHorizontalRule().run()">
          <i class="ri-separator"></i>
        </span>
        <span class="button" :title="$t('hardBreak')" @click="editor.chain().focus().setHardBreak().run()">
          <i class="ri-text-wrap"></i>
        </span>
        <span class="button" :title="$t('insertTable')" @click="
        editor
          .chain()
          .focus()
          .insertTable({ rows: 1, cols: 1, withHeaderRow: false })
          .run()
        ">
          <i class="ri-table-2"></i>
        </span>
        <span class="button" :title="$t('undo')" @click="editor.chain().focus().undo().run()">
          <i class="ri-arrow-go-back-line"></i>
        </span>
        <span class="button" :title="$t('redo')" @click="editor.chain().focus().redo().run()">
          <i class="ri-arrow-go-forward-line"></i>
        </span>
        <div v-if="editor.isActive('table')">
          <b class="m-4">{{ $t("tableTools") }}</b>
          <span class="button" :title="$t('addColumnBefore')" @click="editor.chain().focus().addColumnBefore().run()"
            v-if="editor.can().addColumnBefore()">
            <i class="ri-insert-column-right"></i>
          </span>
          <span class="button" :title="$t('addColumnAfter')" @click="editor.chain().focus().addColumnAfter().run()"
            :disabled="!editor.can().addColumnAfter()"><i class="ri-insert-column-left"></i>
          </span>
          <span class="button" :title="$t('deleteColumn')" @click="editor.chain().focus().deleteColumn().run()"
            v-if="editor.can().deleteColumn()">
            <i class="ri-delete-column"></i>
          </span>
          <span class="button" :title="$t('addRowBefore')" @click="editor.chain().focus().addRowBefore().run()"
            v-if="editor.can().addRowBefore()">
            <i class="ri-insert-row-bottom"></i>
          </span>
          <span class="button" :title="$t('addRowAfter')" @click="editor.chain().focus().addRowAfter().run()"
            v-if="editor.can().addRowAfter()">
            <i class="ri-insert-row-top"></i>
          </span>
          <span class="button" :title="$t('deleteRow')" @click="editor.chain().focus().deleteRow().run()"
            v-if="editor.can().deleteRow()">
            <i class="ri-delete-row"></i>
          </span>
          <span class="button" :title="$t('deleteTable')" @click="editor.chain().focus().deleteTable().run()"
            :disabled="!editor.can().deleteTable()">
            <i class="ri-delete-bin-2-line"></i>
          </span>
          <span class="button" :title="$t('mergeCells')" @click="editor.chain().focus().mergeCells().run()"
            v-if="editor.can().mergeCells()"><i class="ri-merge-cells-horizontal"></i>
          </span>
          <span class="button" :title="$t('splitCell')" @click="editor.chain().focus().splitCell().run()"
            v-if="editor.can().splitCell()">
            <i class="ri-split-cells-vertical"></i>
          </span>
          <span class="button" :title="$t('toggleHeaderColumn')"
            @click="editor.chain().focus().toggleHeaderColumn().run()" v-if="editor.can().toggleHeaderColumn()">
            <i class="ri-layout-column-fill"></i>
          </span>
          <span class="button" :title="$t('toggleHeaderRow')" @click="editor.chain().focus().toggleHeaderRow().run()"
            v-if="editor.can().toggleHeaderRow()">
            <i class="ri-layout-row-fill"></i>
          </span>
          <span class="button" :title="$t('toggleHeaderCell')" @click="editor.chain().focus().toggleHeaderCell().run()"
            v-if="editor.can().toggleHeaderCell()">
            <i class="ri-checkbox-blank-fill"></i>
          </span>
          <!-- <span
            class="button"
            :title="$t('mergeOrSplit')"
            @click="editor.chain().focus().mergeOrSplit().run()"
            v-if="editor.can().mergeOrSplit()"
          >
            mergeOrSplit
          </span>
          <span
        @click="
          editor
            .chain()
            .focus()
            .setCellAttribute('backgroundColor', '#FAF594')
            .run()
        "
        :disabled="!editor.can().setCellAttribute('backgroundColor', '#FAF594')"
      >
        setCellAttribute
      </span>-
      <span
        @click="editor.chain().focus().fixTables().run()"
        :disabled="!editor.can().fixTables()"
      >
        fixTables
      </span>
          <span
            @click="editor.chain().focus().goToNextCell().run()"
            :disabled="!editor.can().goToNextCell()"
          >
            goToNextCell
          </span>
          <span
            @click="editor.chain().focus().goToPreviousCell().run()"
            :disabled="!editor.can().goToPreviousCell()"
          >
            goToPreviousCell
          </span>-->
        </div>
      </div>
    </div>
    <editor-content :editor="editor" v-model="context.model" />
  </div>
</template>

<script>
import { Editor, EditorContent, mergeAttributes } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";

import Image from "@tiptap/extension-image";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TextAlign from "@tiptap/extension-text-align";

const Button = Link.extend({
  name: "button",
  priority: 1001,
  keepOnSplit: false,
  addOptions() {
    return {
      protocols: [],
      HTMLAttributes: {
        class: "btn btn-primary",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `a[data-type="${this.name}"]`,
        getAttrs: (element) => {
          // Check if the element has an attribute
          element.hasAttribute("data-button");
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "a",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-type": this.name,
      }),
      0,
    ];
  },
  addCommands() {
    return {
      setButton:
        (attributes) =>
          ({ chain }) => {
            return chain().setMark(this.name, attributes).run();
          },

      toggleButton:
        (attributes) =>
          ({ chain }) => {
            return chain()
              .toggleMark(this.name, attributes, { extendEmptyMarkRange: true })
              .run();
          },

      unsetButton:
        () =>
          ({ chain }) => {
            return chain()
              .unsetMark(this.name, { extendEmptyMarkRange: true })
              .run();
          },
    };
  },
});

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
});

import "remixicon/fonts/remixicon.css";

export default {
  components: {
    EditorContent,
  },

  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
      editor: null,
      imageModal: false,
      imageToInsert: null,
      imageForm: null,
      isLoading: false,
    };
  },
  computed: {
    model() {
      return this.context.model;
    },
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        StarterKit,
        Link.configure({
          autolink: false,
          openOnClick: false,
          protocols: ['ftp', 'mailto'],
        }),
        Image,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        // Default TableCell
        // TableCell,
        // Custom TableCell with backgroundColor attribute
        CustomTableCell,
        Button,
      ],
      content: this.context.model,
      onUpdate: () => {
        // HTML
        this.context.model = this.editor.getHTML();

        // JSON
        // this.context.model = this.editor.getJSON().toString()
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
  watch: {
    model(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },
  methods: {
    async submitHandler(data) {
      if (data.image[0].url) {
        this.editor.chain().focus().setImage({ src: data.image[0].url }).run();
        this.imageModal = false;
      }
    },
    imageModalOk() {
      this.$formulate.submit("imageInsert");
      //
    },
    debug() {
      this.text = this.editor.getHTML();
    },
    setLink() {
      const url = window.prompt("URL");

      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url, target: null, rel: null })
        .run();
    },
    setButton() {
      const url = window.prompt("URL");

      this.editor
        .chain()
        .focus()
        .extendMarkRange("button")
        .setButton({ href: url })
        .run();
    },
    addImage() {
      this.imageModal = !this.imageModal;
    },
  },
};
</script>

<style lang="scss">
[contenteditable]:focus {
  outline: 0px solid transparent;
}

img {
  &.ProseMirror-selectednode {
    outline: 3px solid #68cef8;
  }
}

.button.is-active {
  background: var(--bs-secondary-bg);
  border: var(--bs-primary-border-subtle) 1px solid ;
  margin: 0px;
}
/*
.button.is-active:hover {
  background: var(--primary);
}*/

.button {
  border: none;
  padding: 10px;
  cursor: pointer;
  display: inline-block;
}

.button:hover {
  background: #eee;
}

.ProseMirror-focused {
  border: 1px solid #b84141 !important;
}

/* Basic editor styles */
.ProseMirror {
  border: var(--bs-border-width) solid var(--bs-border-color);
  padding-top: 0.75em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding-left: 0.75em;
  padding-right: 0.75em;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  outline: 0px solid transparent;
  min-height: 10em;

  >*+* {
    margin-bottom: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    max-height: 70vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

/* Table-specific styling */
.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      >* {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>