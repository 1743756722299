import { render, staticRenderFns } from "./VParticipantsPreview.vue?vue&type=template&id=a0ecfe0e&scoped=true"
import script from "./VParticipantsPreview.vue?vue&type=script&lang=js"
export * from "./VParticipantsPreview.vue?vue&type=script&lang=js"
import style0 from "./VParticipantsPreview.vue?vue&type=style&index=0&id=a0ecfe0e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0ecfe0e",
  null
  
)

export default component.exports