<template>
  <!-- <button v-on:click="loadVideo">Load Video</button>
    <button v-on:click="floatyfloat">Floatyfloat</button>
    <button v-on:click="unfloatyfloat">No Floatyfloat</button>-->
  <div class="container-fluid">
    <div class="row mb-3 mt-3">
      <div
        class="themed-row-col"
        :class="tool == '' ? 'col-md-12' : 'col-md-8'"
      >
        <iframe
          :src="media"
          ref="media"
          id="media"
          allow="display-capture *; camera *; microphone *"
          allowfullscreen
          style="
            width: 100%;
            height: calc(100vh - 100px);
            background: #eee;
            border: none;
          "
        ></iframe>
      </div>
      <div class="col-md-4 themed-row-col">
        <iframe
          v-if="tool != ''"
          :src="tool"
          ref="tool"
          id="tool"
          allow="display-capture *; camera *; microphone *"
          allowfullscreen
          style="
            width: 100%;
            height: calc(100vh - 100px);
            background: #eee;
            border: none;
          "
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import {} from "../store/mutations.type";
import { FETCH_STAGE } from "../store/actions.type";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  name: "Stage",
  components: {},
  watch: {
    stage(newValue) {
      this.media = newValue.media;
      this.tool = newValue.tool;
    },
  },
  beforeRouteUpdate(to, from, next) {
    Promise.all([store.dispatch(FETCH_STAGE, to.params.stage_id)]).then(() => {
      next();
    });
  },
  data() {
    return {
      media: null,
      tool: null,
    };
  },
  computed: {
    ...mapGetters(["stage"]),
  },
  methods: {
    pollData() {
      this.polling = setInterval(() => {
        this.fetchStage();
      }, 300000);
    },
    fetchStage() {
      this.$store.dispatch(
        FETCH_STAGE,
        this._routerRoot._route.path.replace("/stage/", "")
      );
    },
  },
  mounted() {
    /* TODO: this is incredibly awful, but if you don't know how it works, you have to improvise. so here we go... */
    this.$store.dispatch(
      FETCH_STAGE,
      this._routerRoot._route.path.replace("/stage/", "")
    );
    this.pollData();
  },

  created() {},
  destroyed() {},
  beforeDestroy() {
    clearInterval(this.polling);
  },
};
</script>