<template>
  <div>
    <div v-if="isRoomsLoading" class="participant-preview">
      {{ $t("rooms.loading") }}
    </div>
    <div v-else>
      <div v-if="rooms.length === 0" class="participant-preview">
        {{ $t("rooms.empty") }}
      </div>
      <div class="container-fluid">
        <div class="row">
          <RwvRoomsPreview
            v-for="(room, index) in rooms"
            :room="room"
            :roomUrl="roomUrl"
            :key="room.meeting_name + index"
          />
        </div>
      </div>
      <!--<VPagination :pages="pages" :currentPage.sync="currentPage" />-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RwvRoomsPreview from "./VRoomsPreview";
//import VPagination from "./VPagination";
import { FETCH_ROOMS } from "../store/actions.type";

export default {
  name: "RwvRoomsList",
  components: {
    RwvRoomsPreview,
    // VPagination,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "all",
    },
    favorited: {
      type: String,
      required: false,
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 12,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    listConfig() {
      const { type } = this;
      const filters = {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      };
      if (this.favorited) {
        filters.favorited = this.favorited;
      }
      return {
        type,
        filters,
      };
    },
    pages() {
      if (this.isRoomsLoading || this.roomsCount <= this.itemsPerPage) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.roomsCount / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
    },
    ...mapGetters(["roomsCount", "roomUrl", "isRoomsLoading", "rooms"]),
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.filters.offset = (newValue - 1) * this.itemsPerPage;
      this.fetchRooms();
    },
    favorited() {
      this.resetPagination();
      this.fetchRooms();
    },
  },
  mounted() {
    this.fetchRooms();
  },
  methods: {
    fetchRooms() {
      this.$store.dispatch(FETCH_ROOMS, this.listConfig);
    },
    resetPagination() {
      this.listConfig.offset = 0;
      this.currentPage = 1;
    },
  },
};
</script>
