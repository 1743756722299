import ApiService from "@/common/api.service";
import { FETCH_ADMIN_FEATURES } from "./actions.type";
import { SET_ADMIN_FEATURES } from "./mutations.type";

export const state = {
  adminFeatures: {},
  scanHistory: [],
};

const getters = {
  adminFeatures(state) {
    return state.adminFeatures;
  },
  scanHistory(state) {
    return state.scanHistory;
  }
};

export const actions = {
  [FETCH_ADMIN_FEATURES](context) {
    return ApiService.get("general/adminMenu/")
      .then(({ data }) => {
        context.commit(SET_ADMIN_FEATURES, data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  requestAddAuthHistory(context, historyItem) {
    context.commit('addAuthHistory', historyItem)
  }
};

export const mutations = {
  [SET_ADMIN_FEATURES](state, article) {
    state.adminFeatures = article;
  },
  addAuthHistory(state, item) {
    state.scanHistory.unshift(item);
    if (this.scanHistory.length > 7) {
      this.scanHistory.pop();
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
