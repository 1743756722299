<template style="height: 100%">
  <iframe
    @load="load"
    id="rocketChatIframe"
    style="width: 100%; height: 100%; border: none"
    :src="rc_url"
  ></iframe>
</template>

<script>
import { mapGetters } from "vuex";
import { FETCH_RC_TOKEN } from "@/store/actions.type";
import {
  SET_ROCKETCHAT_PATH,
  SET_ROCKETCHAT_URL,
  SET_CHAT_UNREAD_COUNT,
} from "@/store/mutations.type";

export default {
  name: "RocketChat",
  components: {},
  computed: {
    ...mapGetters([
      "rcLoginInformaton",
      "rcLoaded",
      "rc_url",
      "rc_path",
      "isAuthenticated",
      "rcLoggedIn",
      "eventConfig",
    ]),
  },
  props: {
    rc_username: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      source: "",
    };
  },
  watch: {
    rcLoginInformaton(newValue) {
      if (newValue != null && this.rcLoaded) {
        this.logIn(newValue);
        this.go(this.rc_path);
      }
    },
    rc_path(newValue) {
      if (this.rcLoaded == true) {
        this.go(newValue);
      }
    },
    rcLoggedIn(newValue) {
      console.log("isAuthenticated is now " + newValue);
      if (newValue == true) {
        this.fetchRcLoginInformation();
      }
    },
    isAuthenticated(newValue) {
      if (newValue == false) {
        this.logout();
      }
    },
    /*eventConfig(newValue) {
      this.$store.commit(SET_ROCKETCHAT_URL, newValue["rocketChatUrl"]);
    },*/
    rcLoaded(newValue) {},
  },
  created: function () {
    window.addEventListener("message", this.getRocketChatMessage);
  },

  mounted() {
    this.$store.commit(SET_ROCKETCHAT_URL, this.eventConfig["rocketChatUrl"]);
  },
  methods: {
    fetchRcLoginInformation() {
      this.$store.dispatch(FETCH_RC_TOKEN);
    },
    load() {
      this.logout();
      this.$store.commit("setRcLoaded", true);

      if (this.isAuthenticated) {
        this.fetchRcLoginInformation();
      }
    },
    getRocketChatMessage(e) {
      //console.log(e.data.eventName); // event name
      //console.log(e.data.data); // event data
      if (e.data.eventName == "unread-changed") {
        this.$store.commit(SET_CHAT_UNREAD_COUNT, e.data.data);
      }

      /* TODO: think about how to go to a specific channel on home page
      if (e.data.eventName == "global-chat-login-results") {
        //console.log("ONLINE NOW");
        this.go(this.rc_path);
      }*/
    },
    logIn(newValue) {
      //console.log("LOGIN NOW");
      document.querySelector("#rocketChatIframe").contentWindow.postMessage(
        {
          externalCommand: "login-with-token",
          token: newValue.authToken,
        },
        "*"
      );
    },
    go(url) {
      if (this.rcLoaded == true) {
        console.log("GO NOW");
        document.querySelector("#rocketChatIframe").contentWindow.postMessage(
          {
            externalCommand: "go",
            path: url,
          },
          "*"
        );
      }
    },
    logout() {
      // this.$store.commit(SET_ROCKETCHAT_URL, null);
      document.querySelector("#rocketChatIframe").contentWindow.postMessage(
        {
          externalCommand: "logout",
        },
        "*"
      );
    },
  },
};
</script>