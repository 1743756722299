<template>
  <div
    ref="test"
    :style="{
      top: rc_y + 'px',
      left: rc_x + 'px',
      width: rc_width + 'px',
      height: rc_height + 'px',
    }"
    class="fixed-pos"
    :class="{ videoFloater: rc_floaty }"
  >
    <div style="height:100%;">
      <RocketChat v-if="eventConfig.rcEnabled" style="height:100%;" :url="rc_url" />
    </div>
  </div>
</template>

<script>
import RocketChat from "./TRocketChat";
import { mapGetters } from "vuex";

export default {
  name: "FloatingRocketChat",
  components: {
    RocketChat,
  },
  computed: {
    ...mapGetters([
      "rc_url",
      "rc_x",
      "rc_y",
      "rc_width",
      "rc_height",
      "rc_floaty",
      "rc_floatyDisplay",
      "rc_hidden",
      "eventConfig",
    ]),
  },

  methods: {
    removeFloaty(event) {
      event;
    },
  },
  watch: {},
};
</script>

<style scoped>
.videoFloater {
  position: fixed !important;
  right: 0px;
  bottom: 0px;
  left: auto !important;
  top: auto !important;
  width: 500px !important;
  height: 330px !important;
}
.fixed-pos {
  position: absolute;
}

.headerFloater {
  background: #eee;
  height: 30px;
}

.headerFloater .floatRight {
  float: right;
}
.videoTitle {
  padding-left: 0.5em;
}

button {
  color: rgb(182, 182, 182);
  border: none;
}
</style>