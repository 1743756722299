import { ParticipantsService } from "@/common/api.service";
import { FETCH_PARTICIPANTS, FETCH_MEDIA, FETCH_STAGE } from "./actions.type";
import {
  FETCH_START,
  FETCH_END,
  UPDATE_PARTICIPANT_IN_LIST,
  FETCH_MEDIA_START,
  FETCH_MEDIA_END,
  FETCH_STAGE_END,
} from "./mutations.type";
import ApiService from "@/common/api.service";

const state = {
  tags: [],
  participants: [],
  isLoading: true,
  isMediaLoading: true,
  globalmedia: "",
  tool: "",
  chatEnabled: false,
  participantsCount: 0,
  stage: {},
  darkMode: true,
};

const getters = {
  participantsCount(state) {
    return state.participantsCount;
  },
  participants(state) {
    return state.participants;
  },
  isLoading(state) {
    return state.isLoading;
  },
  isMediaLoading(state) {
    return state.isMediaLoading;
  },
  globalmedia(state) {
    return state.globalmedia;
  },
  tool(state) {
    return state.tool;
  },
  chatEnabled(state) {
    return state.chatEnabled;
  },
  tags(state) {
    return state.tags;
  },
  stage(state) {
    return state.stage;
  },
  darkMode(state) {
    return state.darkMode;
  }
};

const actions = {
  [FETCH_PARTICIPANTS]({ commit }, params) {
    commit(FETCH_START);
    return ParticipantsService.query(params.type, params.filters)
      .then(({ data }) => {
        commit(FETCH_END, data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  [FETCH_MEDIA]({ commit }, payload) {
    commit(FETCH_MEDIA_START);
    return ApiService.query("media")
      .then(({ data }) => {
        commit(FETCH_MEDIA_END, data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  [FETCH_STAGE]({ commit }, id) {
    commit(FETCH_START);
    return ApiService.get("stage/get", id)
      .then(({ data }) => {
        commit(FETCH_STAGE_END, data);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  updateDarkMode({ commit }, mode) {
    commit('setDarkMode', mode);

  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [FETCH_START](state) {
    state.isLoading = true;
  },
  [FETCH_END](state, { participants, participantsCount }) {
    state.participants = participants;
    state.participantsCount = participantsCount;
    state.isLoading = false;
  },
  [FETCH_STAGE_END](state, { stage }) {
    state.stage = stage;
    state.isLoading = false;
  },
  [UPDATE_PARTICIPANT_IN_LIST](state, data) {
    state.participants = state.participants.map(participant => {
      if (participant.slug !== data.slug) {
        return participant;
      }
      // We could just return data, but it seems dangerous to
      // mix the results of different api calls, so we
      // protect ourselves by copying the information.
      participant.favorited = data.favorited;
      participant.favoritesCount = data.favoritesCount;
      return participant;
    });
  },
  [FETCH_MEDIA_START](state) {
    state.isMediaLoading = true;
  },
  [FETCH_MEDIA_END](state, { media, tool, chatEnabled }) {
    state.globalmedia = media;
    state.tool = tool;
    state.chatEnabled = parseInt(chatEnabled);
    state.isMediaLoading = false;
  },
  setDarkMode(state, mode)
  {
    state.darkMode = mode;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
