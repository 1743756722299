export default class RulesService {

	listChatRooms(locale = "") {
		return fetch('/apiv2/chat/listChatRooms?lang=' + locale).then(res => res.json()).then(d => d.data);
	}
	listUsersFromPrivateRoom(room) {
		return fetch('/apiv2/chat/listUsersFromPrivateRoom/' + room).then(res => res.json()).then(d => d.data);
	}
	addUsersToPrivateRoom(room, users) {

		const options = {
			method: 'POST',
			body: JSON.stringify(users),
			headers: {
				'Content-Type': 'application/json'
			}
		}
		return fetch('/apiv2/chat/addUsersToPrivateRoom/' + room, options).then(res => res.status === 200 ? res.json() : alert(res.status)).then(d => d.data);
	}
	removeUsersFromPrivateRoom(room, users) {

		const options = {
			method: 'POST',
			body: JSON.stringify(users),
			headers: {
				'Content-Type': 'application/json'
			}
		}
		return fetch('/apiv2/chat/removeUsersFromPrivateRoom/' + room, options).then(res => res.status === 200 ? res.json() : alert(res.status)).then(d => d.data);
	}
	editNameOfPrivateRoom(room, name) {

		const options = {
			method: 'POST',
			body: JSON.stringify({name: name}),
			headers: {
				'Content-Type': 'application/json'
			}
		}
		return fetch('/apiv2/chat/editNameOfPrivateRoom/' + room, options).then(res => res.status === 200 ? res.json() : alert(res.status)).then(d => d.data);
	}

	listChatMessages(room, offset, limit) {
		return fetch('/apiv2/chat/listChatMessages/' + room + "?offset=" + offset + "&limit=" + limit).then(res => res.json()).then(d => d.data);
	}
	/*queueChatMessages(room, lastId, signal) {
		return fetch('/apiv2/chat/queueChatMessages/' + room + "?lastId=" + lastId, { signal }).then(res => res.json()).then(d => d.data);
	}*/
	queueChatMessages(room, lastId) {
		return fetch('/apiv2/chat/queueChatMessages/' + room + "?lastId=" + lastId).then(res => res.json()).then(d => d.data);
	}
	createOneToOne(userId) {
		return fetch('/apiv2/chat/createOneToOne/' + userId).then(res => res.json()).then(d => d.data);
	}
	clearChannel(roomId) {
		return fetch('/apiv2/chat/clearChannel/' + roomId).then(res => res.json()).then(d => d.data);
	}
	sendMessage(room, message, lastId) {

		const options = {
			method: 'POST',
			body: JSON.stringify(message),
			headers: {
				'Content-Type': 'application/json'
			}
		}
		return fetch('/apiv2/chat/sendMessage/' + room + "?lastId=" + lastId, options).then(res => res.status === 200 ? res.json() : '' ).then(d => d.data);
	}
	sendMessageReaction(room, message) {

		const options = {
			method: 'POST',
			body: JSON.stringify(message),
			headers: {
				'Content-Type': 'application/json'
			}
		}
		return fetch('/apiv2/chat/sendMessageReaction/' + room, options).then(res => res.json()).then(d => d.data);
	}
	deleteMessage(room, message) {

		const options = {
			method: 'POST',
			body: JSON.stringify(message),
			headers: {
				'Content-Type': 'application/json'
			}
		}
		return fetch('/apiv2/chat/deleteMessage/' + room, options).then(res => res.json()).then(d => d.data);
	}
	editMessage(room, message) {

		const options = {
			method: 'POST',
			body: JSON.stringify(message),
			headers: {
				'Content-Type': 'application/json'
			}
		}
		return fetch('/apiv2/chat/editMessage/' + room, options).then(res => res.json()).then(d => d.data);
	}
	createPrivateRoom(name) {

		const options = {
			method: 'POST',
			body: JSON.stringify({ name: name }),
			headers: {
				'Content-Type': 'application/json'
			}
		}
		return fetch('/apiv2/chat/createPrivateRoom/', options).then(res => res.status === 200 ? res.json() : alert(res.status)).then(d => d.data);
	}
	sendReadReceipt(room, messageId) {
		return fetch('/apiv2/chat/sendReadReceipt/' + room + "&messageId=" + messageId, options);
	}
	switchFavorite(room) {
		return fetch('/apiv2/chat/switchFavorite/' + room);
	}
	getOneToOneGroupId(userId) {
		return fetch('/apiv2/chat/getOneToOneGroupId/' + userId).then(res => res.json()).then(d => d.data);
	}


}