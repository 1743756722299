import ApiService from "@/common/api.service";
import { FETCH_ROOMS } from "./actions.type";
import {
    FETCH_ROOMS_START,
    FETCH_ROOMS_END,
} from "./mutations.type";

const state = {
    rooms: [],
    roomUrl: "",
    isRoomsLoading: true,
    participantsCount: 0
};

const getters = {
    roomsCount(state) {
        return state.roomCount;
    },
    rooms(state) {
        return state.rooms;
    },
    isRoomsLoading(state) {
        return state.isRoomsLoading;
    },
    roomUrl(state) {
        return state.roomUrl;
    }
};

const actions = {
    [FETCH_ROOMS]({ commit }, params) {
        commit(FETCH_ROOMS_START);
        return ApiService.query('bbbRooms', {
            params: params.filters
        })
            .then(({ data }) => {
                commit(FETCH_ROOMS_END, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    [FETCH_ROOMS_START](state) {
        state.isRoomsLoading = true;
    },
    [FETCH_ROOMS_END](state, { rooms, roomUrl, roomCount }) {
        state.rooms = rooms;
        state.roomCount = roomCount;
        state.roomUrl = roomUrl;
        state.isRoomsLoading = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
