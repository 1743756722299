<template>
  <div style="color:gray;">
      
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RwvRoomsMeta",
  props: {
    participant: {
      type: Object,
      required: true,
    },
    actions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated"]),
  },
  methods: {},
};
</script>
