<template>
  <main class="container">
    <div class="pt-3 pb-3 text-center">
      <b-spinner class="align-center"></b-spinner>
    </div>
  </main>
</template>

<script>
import {} from "../store/mutations.type";
import {
  FETCH_REDIRECT,
  FETCH_REDIRECT_PUBLIC,
  GET_CONFIG,
} from "../store/actions.type";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  name: "Home",
  components: {},
  updated: function () {},
  watch: {
    isPageLoading(newVal) {
      if (!newVal) {
        setTimeout(() => {
          if (this.isAuthenticated) {
            this.$router.push("/" + this.setRedirect);
          } else {
            this.$router.push("/" + this.setRedirect);
          }
        }, Math.floor(Math.random() * 500 + 500));
      }
    },
  },
  computed: {
    ...mapGetters([
      "eventConfig",
      "isAuthenticated",
      "setRedirect",
      "setRedirectPublic",
      "isPageLoading",
    ]),
  },
  methods: {},
  mounted() {
    if (this.isAuthenticated) {
      store.dispatch(FETCH_REDIRECT, {
        lang: this.$i18n.locale,
      });
    } else {
      this.$store.dispatch(GET_CONFIG);
      store.dispatch(FETCH_REDIRECT_PUBLIC, {
        lang: this.$i18n.locale,
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch(GET_CONFIG);
  },

  created() {},
  destroyed() {},
};
</script>