
import { SET_VIDEO, SET_VIDEO_BOX, SET_VIDEO_FLOAT, SET_VIDEO_FROM, HIDE_FLOATY, SHOW_FLOATY } from "./mutations.type";

export const state = {
    embedUrl: null,
    floatyDisplay: false,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    floaty: false,
    videoFrom: null,
};

export const actions = {
};

export const getters = {
    embedUrl: state => {
        return state.embedUrl; 
    },
    x: state => {
        return state.x; 
    },
    y: state => {
        return state.y; 
    },
    width: state => {
        return state.width; 
    },
    height: state => {
        return state.height;
    },
    floaty: state => {
        return state.floaty;
    },
    floatyDisplay: state => {
        return state.floatyDisplay;
    },
    videoFrom: state => {
        return state.videoFrom;
    }
}

export const mutations = {
    [SET_VIDEO](state, embedUrl) {
        state.embedUrl = embedUrl;
    },
    [SET_VIDEO_FLOAT](state, floaty) {
        state.floaty = floaty;
    },
    [SET_VIDEO_FROM](state, from) {
        state.videoFrom = from;
    },
    [SET_VIDEO_BOX](state, [x, y, width, height]) {
        state.x = x;
        state.y = y;
        state.width = width;
        state.height = height;
    },
    [HIDE_FLOATY](state) {
        state.media = null;
        state.floatyDisplay = false;
    },
    [SHOW_FLOATY](state) {
        state.floatyDisplay = true;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
