import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store";

import VueI18n from 'vue-i18n';
import messages from './i18n';

import { CHECK_AUTH } from "./store/actions.type";
import ApiService from "./common/api.service";

import VueFormulate from '@braid/vue-formulate';
import { de } from '@braid/vue-formulate-i18n'
import VFormulateEditor from './components/VFormulateEditor'
import VFormulateSelect from './components/VFormulateSelect'

import axios from "axios";
import VueAxios from "vue-axios";

import PrimeVue from 'primevue/config';
import Message from 'primevue/message';
import DataTable from 'primevue/datatable';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import InputText from 'primevue/inputtext';
import Card from 'primevue/card';
import Textarea from 'primevue/textarea';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import Menubar from 'primevue/menubar';
import Menu from 'primevue/menu';
import Listbox from 'primevue/listbox';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import Chip from 'primevue/chip';
import SelectButton from 'primevue/selectbutton';
import PickList from 'primevue/picklist';
import DataView from 'primevue/dataview';
import Skeleton from 'primevue/skeleton';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Panel from 'primevue/panel';
import Chart from 'primevue/chart';
// import Tooltip from 'primevue/tooltip';

import VueNotification from "@kugatsu/vuenotification";

import { FormCheckboxPlugin, LayoutPlugin, CardPlugin, ListGroupPlugin, DropdownPlugin, NavbarPlugin, TabsPlugin, OverlayPlugin, BadgePlugin, SkeletonPlugin, ModalPlugin, ButtonPlugin, PopoverPlugin, BSpinner, ButtonGroupPlugin, AlertPlugin } from 'bootstrap-vue';

import './app.scss';
import 'vue-select/dist/vue-select.css';
import './assets/styles/layout.scss';


import './assets/styles/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
// import 'primeflex/primeflex.scss';


//import 'bootstrap/js/dist/dropdown'
//import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/tooltip'
import "bootstrap"


Vue.config.productionTip = false;
Vue.component('VFormulateEditor', VFormulateEditor)
Vue.component('VFormulateSelect', VFormulateSelect)

Vue.use(VueI18n);

Vue.use(PrimeVue, { ripple: true });
Vue.component('DataTable', DataTable)
Vue.component('Column', Column)
Vue.component('ColumnGroup', ColumnGroup)
Vue.component('Button', Button)
Vue.component('Card', Card)
Vue.component('Toolbar', Toolbar)
Vue.component('InputText', InputText)
Vue.component('Textarea', Textarea)
Vue.component('ConfirmDialog', ConfirmDialog)
Vue.component('Dialog', Dialog)
Vue.component('Checkbox', Checkbox)
Vue.component('Menubar', Menubar)
Vue.component('Menu', Menu)
Vue.component('Listbox', Listbox)
Vue.component('Dropdown', Dropdown)
Vue.component('Fieldset', Fieldset)
Vue.component('Chip', Chip)
Vue.component('SelectButton', SelectButton)
Vue.component('PickList', PickList)
Vue.component('DataView', DataView)
Vue.component('Skeleton', Skeleton)
Vue.component('Badge', Badge)
Vue.directive('badge', BadgeDirective)
Vue.component('Message', Message)
Vue.component('MultiSelect', MultiSelect)
Vue.component('Calendar', Calendar)
Vue.component('Panel', Panel)
Vue.component('Chart', Chart)
// Vue.directive('tooltip', Tooltip);

Vue.use(ConfirmationService)

Vue.use(VueAxios, axios);

Vue.use(VueFormulate, {
  plugins: [de],
  classes: {
    input: 'form-control',
  },
  library: {
    editor: {
      classification: 'textarea',
      component: 'VFormulateEditor'
    },
    vselect: {
      classification: 'select',
      component: 'VFormulateSelect'
    },
  },
  uploader: Vue.axios,
  uploadUrl: '/upload'
})

Vue.use(LayoutPlugin);
Vue.use(CardPlugin);
Vue.use(DropdownPlugin)
Vue.use(ListGroupPlugin)
Vue.use(NavbarPlugin)
Vue.use(TabsPlugin)
Vue.use(OverlayPlugin)
Vue.use(BadgePlugin)
Vue.use(SkeletonPlugin)
Vue.use(ModalPlugin)
Vue.use(ButtonPlugin)
Vue.use(PopoverPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(AlertPlugin)
Vue.use(FormCheckboxPlugin)
Vue.component('b-spinner', BSpinner)
// Vue.directive('b-tooltip', VBTooltip)

Vue.use(VueNotification, {
  timer: 5,
  position: "bottomCenter",
});

Vue.mixin({
  methods: {
    dateToISOLikeButLocal: function (date) {
      try {
        const offsetMs = date.getTimezoneOffset() * 60 * 1000;
        const msLocal = date.getTime() - offsetMs;
        const dateLocal = new Date(msLocal);
        const iso = dateLocal.toISOString();
        const isoLocal = iso.slice(0, 16);
        return isoLocal;
      } catch (error) {
        return '';
      }


    }
  }
});

ApiService.init();


const random = (length = 16) => {
  // Declare all characters
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  // Pick characers randomly
  let str = '';
  for (let i = 0; i < length; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return str;
};


if (sessionStorage.getItem('sessionKey') == null) {
  // store random key in session storage if not set
  const d = new Date();
  let text = d.toISOString();
  sessionStorage.setItem('sessionKey', text + random());
}

const dateTimeFormats = {
  'en-GB': {
    long: {
      year: "numeric", month: "long", day: "numeric", hour: 'numeric',
      minute: 'numeric'
    },
    short: {
      year: "numeric", month: "numeric", day: "numeric", hour: 'numeric',
      minute: 'numeric'
    },
    date: {
      year: "numeric", month: "long", day: "numeric",
    },
    month: {
      month: "short", year: "numeric",
    },
    weekday: {
      weekday: "long",
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
    }
  },
  de: {
    long: {
      year: "numeric", month: "long", day: "numeric", hour: 'numeric',
      minute: 'numeric'
    },
    short: {
      year: "numeric", month: "numeric", day: "numeric", hour: 'numeric',
      minute: 'numeric'
    },
    date: {
      year: "numeric", month: "long", day: "numeric",
    },
    month: {
      month: "short", year: "numeric",
    },
    weekday: {
      weekday: "long",
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
    }
  }
}

export const i18n = new VueI18n({
  fallbackLocale: 'en',
  messages,
  dateTimeFormats,
  locale: 'en',
});

var firstRun = true;

router.beforeEach((to, from, next) =>
  Promise.all([store.dispatch(CHECK_AUTH, {
    'site': to.fullPath, 'site_from': from.fullPath,
    'is_video_visible': store.getters.floatyDisplay,
    'video_url': store.getters.embedUrl,
    'visibility_state': document.visibilityState,
    'session_string': sessionStorage.getItem('sessionKey'),
  })]).then(
    function (val) {
      if (firstRun) {
        // val[0] because as return of the first promise.
        if (val[0] !== undefined) {
          // i18n.locale = val[0].config.lang1;
          i18n.fallbackLocale = val[0].config.lang1;
        }
      }
      firstRun = false;
      next();
    }
  )
);

router.beforeEach((to, from, next) => {
  // Make player floating when going away from a video page (which is only "home" this time)
  if (!store.getters.floaty) {
    store.commit("setVideoFloat", true);
    store.commit("setVideoFrom", from.fullPath);
  }
  if (from.name == "Livestream") {
    store.commit("hideRocketChat");
  }
  if (from.name == "Chat" || (from.name == "DirectChat" && to.name != "Chat")) {
    store.commit("hideRocketChat");
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isAuthenticated) {
      next({ name: 'Home' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
