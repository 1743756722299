
import { SET_ROCKETCHAT_URL, SET_ROCKETCHAT_BOX, SET_ROCKETCHAT_FLOAT, HIDE_ROCKETCHAT, SET_ROCKETCHAT_PATH, SET_CHAT_UNREAD_COUNT } from "./mutations.type";

export const state = {
    rc_url: null,
    rc_path: null,
    rc_floatyDisplay: false,
    rc_x: 0,
    rc_y: 0,
    rc_width: 0,
    rc_height: 0,
    rc_floaty: false,
    rc_unreadCount: null,
};

export const actions = {
};

export const getters = {
    rc_url: state => {
        return state.rc_url; 
    },
    rc_path: state => {
        return state.rc_path;
    },
    rc_x: state => {
        return state.rc_x; 
    },
    rc_y: state => {
        return state.rc_y; 
    },
    rc_width: state => {
        return state.rc_width; 
    },
    rc_height: state => {
        return state.rc_height;
    },
    rc_floaty: state => {
        return state.rc_floaty;
    },
    rc_floatyDisplay: state => {
        return state.rc_floatyDisplay;
    },
    rc_unreadCount: state => {
        return state.rc_unreadCount;
    }
}

export const mutations = {
    [SET_ROCKETCHAT_URL](state, rc_url) {
        state.rc_url = rc_url;
    },
    [SET_CHAT_UNREAD_COUNT](state, rc_unreadCount) {
        state.rc_unreadCount = rc_unreadCount;
    },
    [SET_ROCKETCHAT_PATH](state, rc_path) {
        state.rc_path = rc_path;
    },
    [SET_ROCKETCHAT_FLOAT](state, rc_floaty) {
        state.rc_floaty = rc_floaty;
    },
    [SET_ROCKETCHAT_BOX](state, [rc_x, rc_y, rc_width, rc_height]) {
        state.rc_x = rc_x;
        state.rc_y = rc_y;
        state.rc_width = rc_width;
        state.rc_height = rc_height;
    },
    [HIDE_ROCKETCHAT](state) {
        state.rc_floatyDisplay = false;
        state.rc_x = 0;
        state.rc_y = 0;
        state.rc_width = 0;
        state.rc_height = 0;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
