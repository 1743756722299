<template>
  <div>
    <hr />

    <p class="text-start">
      <span class="small">powered by</span> <br />
      <a href="https://mu-te.de">
        <img class="img-fluid" style="width: 20%" alt="mu&te Logo" v-if="!darkMode" src="/dist/img/mute-logo-light.png" />
        <img class="img-fluid" style="width: 20%" alt="mu&te Logo" v-if="darkMode" src="/dist/img/mute-logo-dark.png" />
      </a>
    </p>
    <p class="small text-start">
      <a target="_blank" href="https://mu-te.de/impressum/">Impressum&nbsp;&amp;&nbsp;Datenschutz</a>
      |
      <a target="_blank" href="https://vi-meet.de/agb.html">AGB</a>
      |
      <a target="_blank" href="https://wiki.mute-events.de/">Hilfe</a>
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheLoginFooter",
  computed: {
    ...mapGetters(["darkMode"]),

  },
};

</script>