import { RCService } from "@/common/api.service";
import {
    FETCH_RC_TOKEN,
    RC_LOGOUT,
} from "./actions.type";
import {
    SET_RC_TOKEN,
    SET_LOGGEDIN_FALSE,
    SET_RC_LOGGEDIN
} from "./mutations.type";

const initialState = {
    rcLoginInformaton: {
        userId: "",
        authToken: "",
    },
    rcLoggedIn: false,
    rcLoaded: false,
};

export const state = { ...initialState };

export const actions = {
    [FETCH_RC_TOKEN](context, slug) {
        return RCService.get("login").then(({ data }) => {
            context.commit(SET_RC_TOKEN, data);
            return data;
        })
            .catch(error => {
                throw new Error(error);
            });
    },
    [RC_LOGOUT](context)
    {
        context.commit(SET_LOGGEDIN_FALSE);
    }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
    [SET_RC_TOKEN](state, rcLoginInformaton) {
        state.rcLoginInformaton = rcLoginInformaton;
    },
    [SET_LOGGEDIN_FALSE](state)
    {
        state.rcLoginInformaton = null;
        state.rcLoggedIn = false;
    },
    [SET_RC_LOGGEDIN](state, value)
    {
        state.rcLoggedIn = value;
    },
    setRcLoaded(state, rcLoaded)
    {
        state.rcLoaded = rcLoaded;
    }
};

const getters = {
    rcLoginInformaton(state) {
        return state.rcLoginInformaton;
    },
    rcLoggedIn(state) {
        return state.rcLoggedIn;
    },
    rcLoaded(state) {
        return state.rcLoaded;
    }
};


export default {
    state,
    actions,
    mutations,
    getters
};
