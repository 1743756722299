<template>
  <div>
    <RocketChat :rc_username="this.$attrs.rcname" />
  </div>
</template>

<script>
import RocketChat from "@/components/RocketChat.vue";
import { SET_ROCKETCHAT_BOX } from "@/store/actions.type";

export default {
  name: "TRocketChat",
  components: {
    RocketChat,
  },
  props: {
    url: String,
  },
};
</script>