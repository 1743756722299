<template>
  <div class="col-lg-12 col-md-12">
    <component :is="navigationEnabled && !small ? 'router-link' : 'div'" class="h-100"
      :to="'/participant/' + participant.user_id">
      <div class="d-flex" :class="[small ? 'p-2' : 'p-3', navigationEnabled ? 'hover' : '']">
        <div class="flex align-items-center justify-content-center mr-2">
          <component :is="navigationEnabled && small ? 'router-link' : 'div'" class="h-100"
            :to="'/participant/' + participant.user_id">
            <img v-if="participant.image" class="rounded-circle" :style="{
              width: small ? '2rem' : '3rem',
              height: small ? '2rem' : '3rem',
              position: 'relative',
            }" :src="participant.image" />
            <div v-if="!participant.image" class="rounded-circle text-muted bg-body-secondary" :style="{
              width: small ? '1.5rem' : '3rem',
              height: small ? '1.5rem' : '3rem',
              position: 'relative',
            }">
              <i class="ri-user-line noprofilepic"></i>
            </div>
          </component>
        </div>
        <div class="
                    flex-grow-1
                    align-self-center align-items-center
                    justify-content-left
                    ps-2
                  ">
          <component :is="navigationEnabled && small ? 'router-link' : 'div'" class="h-100"
            :to="'/participant/' + participant.user_id">
            <h6 class="mb-0 text-body">
              {{ participant.name }} <!--({{ participant.user_id }})-->
            </h6>
            <template v-if="!small">{{ participant.title }}</template>
          </component>
        </div>
        <div class="d-flex align-items-center justify-content-right">
          <ChatButton v-if="createChat && eventConfig.chatEnabled" :userid="participant.user_id" />
          <router-link @click.native="$emit('navigation')" v-if="
            createChat &&
            eventConfig.rcEnabled &&
            participant.rc_username
          " :to="'/rc/' + participant.rc_username" type="button" class="btn btn-secondary btn-sm"><i
              class="ri-message-2-fill"></i>
          </router-link>
          <template v-if="selectable">
            <span v-if="disabled"><i class="pi pi-check"></i>
            </span>
            <Checkbox v-else v-model="checked" :disabled="disabled" :binary="true" />
          </template>
          <Button v-if="deleteButton" @click="$emit('deleteButtonPressed')" icon="pi pi-trash"
            class="p-button-sm p-button-danger p-button-text" />
        </div>
      </div>
    </component>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import ChatButton from "./ChatButton";
import { USER_IMAGE_URL } from "@/common/config";
import ChatService from "../service/ChatService";

export default {
  name: "RwvParticipantsPreview",
  components: { ChatButton },
  props: {
    participant: { type: Object, required: true },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    createChat: {
      type: Boolean,
      required: false,
      default: false,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    navigationEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      userImageUrl: USER_IMAGE_URL,
      service: null,
      checked: false,
    };
  },
  computed: {
    ...mapGetters(["eventConfig"]),
    participantLink() {
      return {
        name: "participant",
        params: {
          slug: this.participant.user_id,
        },
      };
    },
  },
  watch: {},
  created() {
    this.service = new ChatService();
    this.checked = this.selected;
    this.$watch("checked", (val) => {
      if (val === true) {
        this.$emit("selected");
      } else {
        this.$emit("unselected");
      }
    });
  },
  methods: {
    createChatRoom() {
      this.service.createOneToOne(this.participant.user_id).then((data) => {
        this.$router.push("chat/" + data.room_id);
      });

      return false;
    },
  },
};
</script>

<style scoped>
.hover:hover {
  color: inherit;
  text-decoration: inherit;
  background: var(--bs-secondary-bg);
}

.hover {
  transition: background-color 0.5s;
}

.noprofilepic {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
}

.p-button-sm {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
a {
  text-decoration: none;
}
</style>
