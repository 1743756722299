var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12 col-md-12"},[_c(_vm.navigationEnabled && !_vm.small ? 'router-link' : 'div',{tag:"component",staticClass:"h-100",attrs:{"to":'/participant/' + _vm.participant.user_id}},[_c('div',{staticClass:"d-flex",class:[_vm.small ? 'p-2' : 'p-3', _vm.navigationEnabled ? 'hover' : '']},[_c('div',{staticClass:"flex align-items-center justify-content-center mr-2"},[_c(_vm.navigationEnabled && _vm.small ? 'router-link' : 'div',{tag:"component",staticClass:"h-100",attrs:{"to":'/participant/' + _vm.participant.user_id}},[(_vm.participant.image)?_c('img',{staticClass:"rounded-circle",style:({
            width: _vm.small ? '2rem' : '3rem',
            height: _vm.small ? '2rem' : '3rem',
            position: 'relative',
          }),attrs:{"src":_vm.participant.image}}):_vm._e(),_vm._v(" "),(!_vm.participant.image)?_c('div',{staticClass:"rounded-circle text-muted bg-body-secondary",style:({
            width: _vm.small ? '1.5rem' : '3rem',
            height: _vm.small ? '1.5rem' : '3rem',
            position: 'relative',
          })},[_c('i',{staticClass:"ri-user-line noprofilepic"})]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"flex-grow-1 align-self-center align-items-center justify-content-left ps-2"},[_c(_vm.navigationEnabled && _vm.small ? 'router-link' : 'div',{tag:"component",staticClass:"h-100",attrs:{"to":'/participant/' + _vm.participant.user_id}},[_c('h6',{staticClass:"mb-0 text-body"},[_vm._v("\n            "+_vm._s(_vm.participant.name)+" ")]),_vm._v(" "),(!_vm.small)?[_vm._v(_vm._s(_vm.participant.title))]:_vm._e()],2)],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center justify-content-right"},[(_vm.createChat && _vm.eventConfig.chatEnabled)?_c('ChatButton',{attrs:{"userid":_vm.participant.user_id}}):_vm._e(),_vm._v(" "),(
          _vm.createChat &&
          _vm.eventConfig.rcEnabled &&
          _vm.participant.rc_username
        )?_c('router-link',{staticClass:"btn btn-secondary btn-sm",attrs:{"to":'/rc/' + _vm.participant.rc_username,"type":"button"},nativeOn:{"click":function($event){return _vm.$emit('navigation')}}},[_c('i',{staticClass:"ri-message-2-fill"})]):_vm._e(),_vm._v(" "),(_vm.selectable)?[(_vm.disabled)?_c('span',[_c('i',{staticClass:"pi pi-check"})]):_c('Checkbox',{attrs:{"disabled":_vm.disabled,"binary":true},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}})]:_vm._e(),_vm._v(" "),(_vm.deleteButton)?_c('Button',{staticClass:"p-button-sm p-button-danger p-button-text",attrs:{"icon":"pi pi-trash"},on:{"click":function($event){return _vm.$emit('deleteButtonPressed')}}}):_vm._e()],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }