<template>
  <main class="container">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-3"
    >
      <h1 class="h2">{{ $t('backend.rooms')}}</h1>
      <div class="btn-toolbar mb-2 mb-md-0"></div>
    </div>

    <RwvRoomsList type="all" />
  </main>
</template>
<script>
import RwvRoomsList from "@/components/RoomsList";

export default {
  name: "Rooms",
  components: {
    RwvRoomsList,
  },
};
</script>
