<template>
  <div style="height:100%">
    <iframe
      width="100%"
      height="100%"
      :src="embedUrl"
      frameborder="0"
      allow="display-capture *; camera *; microphone *"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "FloatingVideo",
  props: {
    embedUrl: String,
  },
};
</script>