<template>
  <div>

    <template-loader v-if="!pageErrors" v-bind:data="page" v-bind:type="page.template" v-bind:loading="isPageLoading" />
    <div v-else>
      <div class="d-flex align-items-center justify-content-center h-100">
        <div class="text-center">
          <h1 class="display-1">404</h1>
          <p class="lead">
            {{ $t('404.notFoundText') }}
          </p>
          <router-link to="/" class="btn btn-primary">{{ $t('404.goHome') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { USER_IMAGE_URL } from "@/common/config";
import {
  FETCH_PAGE,
  FETCH_PAGE_PUBLIC,
  GET_CONFIG,
} from "@/store/actions.type";
import TemplateLoader from "@/components/TemplateLoader";

export default {
  name: "Page",
  components: { TemplateLoader },
  beforeRouteUpdate(to, from, next) {
    store.dispatch(FETCH_PAGE, {
      page_name: to.params.page_id,
      lang: this.$i18n.locale,
    });
    next()
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  data() {
    return {
      userImageUrl: USER_IMAGE_URL,
      live: false,
      past: false,
      future: false,
      startIsToday: false,
      endIsToday: false,
      lang: false,
      polling: null,
    };
  },
  watch: {
    page(page) { },
    "$i18n.locale": function (newVal, oldVal) {
      this.fetch();
    },
  },
  mounted() {
    this.fetch();
    this.pollData();
    if (!this.isAuthenticated) {
      this.$store.dispatch(GET_CONFIG);
    }
  },
  methods: {
    ifempty(test, fallback) {
      if (test === "" || test == null) return fallback;
      return test;
    },
    fetch() {
      if (this.isAuthenticated) {
        store.dispatch(FETCH_PAGE, {
          page_name: this.$route.params.page_id,
          lang: this.$i18n.locale,
        });
      } else {
        store.dispatch(FETCH_PAGE_PUBLIC, {
          page_name: this.$route.params.page_id,
          lang: this.$i18n.locale,
        });
      }
    },
    pollData() {
      this.polling = setInterval(() => {
        this.fetch();
      }, 300000);
    },
    ifemptyhtml(test, fallback) {
      if (test === "" || test === "<p></p>" || test == null) return fallback;
      return test;
    },
  },
  updated() { },
  computed: {
    ...mapGetters([
      "page",
      "isPageLoading",
      "pageErrors",
      "currentUser",
      "isAuthenticated",
      "eventConfig",
    ]),
  },
};
</script>
