import ApiService from "@/common/api.service";
import { FETCH_WEBINARS, FETCH_WEBINAR } from "./actions.type";
import {
    FETCH_WEBINARS_START,
    FETCH_WEBINARS_END,
    FETCH_WEBINAR_START,
    FETCH_WEBINAR_END,
} from "./mutations.type";

const state = {
    webinars: [],
    webinar: [],
    webinarCount: 0,
    isWebinarsLoading: true,
    participantsCount: 0
};

const getters = {
    webinarCount(state) {
        return state.webinarCount;
    },
    webinars(state) {
        return state.webinars;
    },
    isWebinarsLoading(state) {
        return state.isWebinarsLoading;
    },
    webinarUrl(state) {
        return state.webinarUrl;
    },
    webinar(state) {
        return state.webinar;
    }
};

const actions = {
    [FETCH_WEBINARS]({ commit }, params) {
        commit(FETCH_WEBINARS_START);
        return ApiService.query('webinar', {
            params: params.filters
        })
            .then(({ data }) => {
                commit(FETCH_WEBINARS_END, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    },
    [FETCH_WEBINAR]({ commit }, id) {
        commit(FETCH_WEBINARS_START);
        return ApiService.query('webinar/get/' + id)
            .then(({ data }) => {
                commit(FETCH_WEBINAR_END, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    [FETCH_WEBINARS_START](state) {
        state.isWebinarsLoading = true;
    },
    [FETCH_WEBINARS_END](state, { webinars, webinarUrl, webinarCount }) {
        state.webinars = webinars;
        state.webinarCount = webinarCount;
        state.webinarUrl = webinarUrl;
        state.isWebinarsLoading = false;
    },
    [FETCH_WEBINAR_START](state) {
        state.isWebinarsLoading = true;
    },
    [FETCH_WEBINAR_END](state, { videocallUrl, roomUrl, data }) {
        state.webinar = data;
        state.isWebinarsLoading = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
