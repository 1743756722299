import ApiService from "@/common/api.service";
import { FETCH_MENU, FETCH_MENU_PUBLIC, CLEAR_MENU } from "./actions.type";
import {
    FETCH_MENU_START,
    FETCH_MENU_END,
} from "./mutations.type";

const state = {
    menuItems: [],
    isMenuLoading: true,
};

const getters = {
    menuItems(state) {
        return state.menuItems;
    },
    isMenuLoading(state) {
        return state.isMenuLoading;
    },
};

const actions = {
    [FETCH_MENU]({ commit }, params) {
        commit(FETCH_MENU_START);
        return ApiService.get('menu')
            .then(({ data }) => {
                commit(FETCH_MENU_END, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    },
    [FETCH_MENU_PUBLIC]({ commit }, params) {
        commit(FETCH_MENU_START);
        return ApiService.get('menuPublic')
            .then(({ data }) => {
                commit(FETCH_MENU_END, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    },
    [CLEAR_MENU]({ commit }) {
        commit(FETCH_MENU_END, {'menuItems': []})
    }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    [FETCH_MENU_START](state) {
        state.isMenuLoading = true;
    },
    [FETCH_MENU_END](state, { menuItems }) {
        state.menuItems = menuItems;
        state.isMenuLoading = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
