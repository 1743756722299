
import Vue from "vue";
import {
  ParticipantsService,
  FavoriteService
} from "@/common/api.service";
import {
  FETCH_PARTICIPANT,
  FAVORITE_ADD,
  FAVORITE_REMOVE,
} from "./actions.type";
import {
  RESET_STATE,
  SET_PARTICIPANT,
  UPDATE_PARTICIPANT_IN_LIST
} from "./mutations.type";

const initialState = {
  participant: {
    name: "",
    email: "",
  },
  isParticipantLoading: true,
};


export const state = { ...initialState };

export const actions = {
  async [FETCH_PARTICIPANT](context, user_id) {
    context.commit('setParticipantLoadingStart', true);
    const { data } = await ParticipantsService.get([user_id]);
    context.commit(SET_PARTICIPANT, data.participant);
    return data;
  },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  [SET_PARTICIPANT](state, participant) {
    state.participant = participant;
    state.isParticipantLoading = false;
  },
  [RESET_STATE]() {
    for (let f in state) {
      Vue.set(state, f, initialState[f]);
    }
  },
  setParticipantLoadingStart() {
    state.isParticipantLoading = true;
  }
};

const getters = {
  participant(state) {
    return state.participant;
  },
  isParticipantLoading(state) {
    return state.isParticipantLoading;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
