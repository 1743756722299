var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-4"},[(!_vm.darkMode)?_c('img',{staticClass:"brand",staticStyle:{"max-height":"90px","max-width":"100%"},attrs:{"alt":_vm.eventConfig.titleLang1,"src":_vm.eventConfig.logo && _vm.eventConfig.logo.length > 0
        ? _vm.eventConfig.logo[0].url
        : '/dist/img/mute-logo-light.png'}}):_vm._e(),_vm._v(" "),(_vm.darkMode)?_c('img',{staticClass:"brand",staticStyle:{"max-height":"90px","max-width":"100%"},attrs:{"alt":_vm.eventConfig.titleLang1,"src":_vm.eventConfig.logoDark && _vm.eventConfig.logoDark.length > 0
        ? _vm.eventConfig.logoDark[0].url
        : (_vm.eventConfig.logo && _vm.eventConfig.logo.length > 0
          ? _vm.eventConfig.logo[0].url
          : '/dist/img/mute-logo-dark.png')}}):_vm._e()]),_vm._v(" "),(_vm.eventConfig.lang1 == _vm.lang)?_c('h3',{staticClass:"mb-4"},[_vm._v("\n    "+_vm._s(_vm.eventConfig.titleLang1)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.eventConfig.lang2 == _vm.lang)?_c('h3',{staticClass:"mb-4"},[_vm._v("\n    "+_vm._s(_vm.eventConfig.titleLang2)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.eventConfig.lang3 == _vm.lang)?_c('h3',{staticClass:"mb-4"},[_vm._v("\n    "+_vm._s(_vm.eventConfig.titleLang3)+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }