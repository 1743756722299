import ApiService from "@/common/api.service";
import { FETCH_CALENDAR, ADM_FETCH_CALENDAR_ITEM, FETCH_CALENDAR_PUBLIC } from "./actions.type";
import {
    FETCH_CALENDAR_START,
    FETCH_CALENDAR_END,
} from "./mutations.type";

const state = {
    calendars: [],
    calendarCount: true,
    participantsCount: 0,
    isCalendarLoading: false,
    videocallUrl: null,
    calendarroomUrl: null,
    calendarsError: false,
};

const getters = {
    calendarCount(state) {
        return state.calendarCount;
    },
    calendars(state) {
        return state.calendars;
    },
    isCalendarLoading(state) {
        return state.isCalendarLoading;
    },
    videocallUrl(state) {
        return state.videocallUrl;
    },
    calendarroomUrl(state) {
        return state.calendarroomUrl;
    },
    calendarsError(state) {
        return state.calendarsError;
    }
};

const actions = {
    [FETCH_CALENDAR]({ commit }, params) {
        commit(FETCH_CALENDAR_START);
        return ApiService.query('calendar', {
            params: params.filters
        })
            .then(({ data }) => {
                commit(FETCH_CALENDAR_END, data);
            })
            .catch(error => {
                commit('calendarError');
                throw new Error(error);
            });
    },
    [FETCH_CALENDAR_PUBLIC]({ commit }, params) {
        commit(FETCH_CALENDAR_START);
        return ApiService.query('calendarPublic', {
            params: params.filters
        })
            .then(({ data }) => {
                commit(FETCH_CALENDAR_END, data);
            })
            .catch(error => {
                commit('calendarError');
                throw new Error(error);
            });
    },
    [ADM_FETCH_CALENDAR_ITEM]({ commit }, id) {
        commit(FETCH_CALENDAR_START);
        return ApiService.query('calendar/details/' + id)
            .then(({ data }) => {
                commit(FETCH_CALENDAR_END, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    [FETCH_CALENDAR_START](state) {
        state.isCalendarLoading = true;
    },
    [FETCH_CALENDAR_END](state, { calendars, calendarCount, roomUrl, videocallUrl }) {
        state.calendars = calendars;
        state.calendarCount = calendarCount;
        state.isCalendarLoading = false;
        state.videocallUrl = videocallUrl;
        state.calendarroomUrl = roomUrl;
        state.calendarsError = false;

    },
    calendarError(state)
    {
        state.calendarsError = true;
        state.isCalendarLoading = false;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
